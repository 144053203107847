<template>
  <vp-base-layout>
    <template #actions-end>
      <ion-button router-link="/settings">
        <ion-icon
          :icon="settingsOutline" />
      </ion-button>
    </template>

    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="ion-text-center">
            <h1>{{ agencyName }}</h1>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <h4>
            매입 <ion-text color="primary">
              {{ cnt1 }}
            </ion-text>건
          </h4>
          <h4>
            미수거 <ion-text color="danger">
              {{ cnt2 }}
            </ion-text>건
          </h4>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center" />
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            expand="full"
            size="large"
            router-link="/pickup">
            수거하기
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            expand="full"
            size="large"
            router-link="/deposit">
            입금하기
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            expand="full"
            size="large"
            router-link="/pickup-state">
            수거내역
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            expand="full"
            size="large"
            router-link="/agency-list">
            대리점관리
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonButton, 
  IonGrid,
  IonRow, 
  IonCol,
  IonText,
  IonIcon
} from '@ionic/vue'
import { ref, inject, onMounted } from 'vue'
import { settingsOutline } from 'ionicons/icons'
import { getToken } from '@/utils/auth'
import { getDept } from '@/api/permission/dept'
import { getOrders } from '@/api/purchase/purchase'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const token = getToken()
const jwtDecode = inject('$jwtDecode')
const user = jwtDecode(token)

const agencyName = ref(undefined)
const cnt1 = ref(0)
const cnt2 = ref(0)

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999
})

const getDeptInfo = async (id) => {
  const agency = await getDept(id)
  agencyName.value = agency.data.deptName
}

const getOrderList = async (query) => {
  const orders = await getOrders(query)
  // 매입 건수(수거)
  cnt1.value = orders.data.results.filter((item) => Number(item.order_state_code) === 2).length
  // 미수거 건수(접수)
  cnt2.value = orders.data.results.filter((item) => Number(item.order_state_code) === 1).length
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await getDeptInfo(user.dept_id)
  await getOrderList(pageInfo.value)
})
</script>
